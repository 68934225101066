$(document).on("click", '#select-all', function(event) {
    if(this.checked) {
        $(':checkbox').prop('checked', true);
    } else {
        $(':checkbox').prop('checked', false);
    }
});

$.fn.selectpicker.Constructor.BootstrapVersion = '5';

$('.bootstrap-select').selectpicker();

// Enables tooltips
[].slice.call($('[data-bs-toggle="tooltip"]')).map(function (el) {
  return new bootstrap.Tooltip(el)
});

$('.bi-copy').click(function(event) {
    var linkInput = $(this).parent().find('.copy-share-link');
    linkInput.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Failed to copy the link.');
    }
    $('#link-copied').removeClass("d-none")
    setTimeout(function() {
        $('#link-copied').fadeOut('slow', function() {
            $(this).addClass('d-none');
        });
    }, 1500);
});
